import { FC } from 'react'
import React from 'react'
import { Flex, Badge } from '@radix-ui/themes'

interface PricingCardTagProps {
    text: string
}

const PricingCardTag: FC<PricingCardTagProps> = (
    props: PricingCardTagProps
) => {
    return (
        <Flex className=" absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20">
            <Badge variant="solid">{props.text}</Badge>
        </Flex>
    )
}

export default PricingCardTag
