import { FC, useEffect, useRef, useState } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    ScriptableContext,
    scales,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import type {
    ChartData,
    ChartOptions,
    CoreScaleOptions,
    Scale,
    Tick,
} from 'chart.js'
import zoomPlugin from 'chartjs-plugin-zoom'
import React from 'react'
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types'
import { themeAtom } from '../../shared/atoms'
import { useRecoilValue } from 'recoil'
import { Box, Flex, Select } from '@radix-ui/themes'
import { set } from 'react-hook-form'

interface LineProps {
    options: ChartOptions<'line'>
    data: ChartData<'line'>
}

interface StaticLabelConfig {
    content: string | number
    position: 'left' | 'right'
    fill: string // RGB or color
    textColor: string
}

interface Dataset {
    label: string
    data: number[]
    borderColor: string
    backgroundColor: string
    borderDash?: number[]
    borderWidth?: number
    pointRadius?: number
    staticLabel?: StaticLabelConfig[]
    // ... other properties ...
}

interface PriceHistoryChartProps {
    priceHistory: { timestamp: Date; price: number }[]
    initialPrice: number
    targetPrice: number
}

const PriceHistoryChart: FC<PriceHistoryChartProps> = (
    props: PriceHistoryChartProps
) => {
    const theme = useRecoilValue(themeAtom)
    const { priceHistory, initialPrice, targetPrice } = props
    const [priceHistoryData, setPriceHistoryData] = useState<
        typeof priceHistory
    >([])
    const [pointBackgroundColor, setPointBackgroundColor] = useState<string[]>(
        []
    )
    const [pointBorderColor, setPointBorderColor] = useState<string[]>([])
    const [pointBorderWidth, setPointBorderWidth] = useState<string[]>([])

    const filterPriceHistoryByDate = (value: '3' | '6' | '12' | '24') => {
        // Check if priceHistory is defined and has at least one element
        if (priceHistory && priceHistory.length > 0) {
            // Convert value to number
            const months = Number(value)

            // Calculate the date months ago
            const monthsAgo = new Date(
                priceHistory[priceHistory.length - 1].timestamp
            )
            monthsAgo.setMonth(monthsAgo.getMonth() - months)

            // Filter price history by date
            const filteredData = priceHistory.filter(
                (item) => new Date(item.timestamp) > monthsAgo
            )

            // Update state
            setPriceHistoryData(filteredData)
            // Set point colors. Values that are the same in a row should be transparent
            setPointBackgroundColor(
                filteredData.map((item, index, array) =>
                    index < array.length - 1 &&
                    item.price === array[index + 1].price
                        ? 'transparent'
                        : '#fff'
                )
            )
            setPointBorderColor(
                filteredData.map((item, index, array) =>
                    index < array.length - 1 &&
                    item.price === array[index + 1].price
                        ? 'transparent'
                        : '#3E63DD'
                )
            )
            setPointBorderWidth(
                filteredData.map((item, index, array) =>
                    index < array.length - 1 &&
                    item.price === array[index + 1].price
                        ? '0'
                        : '2'
                )
            )
        }
    }

    useEffect(() => {
        filterPriceHistoryByDate('3')
    }, [priceHistory])

    const chartRef = useRef<ChartJSOrUndefined<'line', number[], string>>(null)

    function crossLineLabel(
        chart: ChartJS<'line', number[], string>,
        event: any
    ) {
        const {
            canvas,
            ctx,
            chartArea: { left, right, top, bottom },
        } = chart
        const { x, y } = chart.scales
        const { data } = chart
        const { datasets } = data
        const xValue = x.getValueForPixel(event.nativeEvent.offsetX) || 0
        const yValue = y.getValueForPixel(event.nativeEvent.offsetY) || 0

        const yTextWidth =
            ctx.measureText(yValue.toFixed(2).toString()).width + 10

        // yLabel
        ctx.beginPath()
        ctx.fillStyle = 'rgba(132, 132, 132, 0.9)'
        ctx.roundRect(
            left - yTextWidth,
            event.nativeEvent.offsetY - 10,
            yTextWidth,
            20,
            4
        )
        ctx.fill()
        ctx.closePath()
        ctx.beginPath()
        ctx.fillStyle = '#fff'
        ctx.textAlign = 'center'
        ctx.textBaseline = 'middle'
        ctx.fillText(
            yValue.toFixed(2),
            yTextWidth / 2 + 5,
            event.nativeEvent.offsetY
        )
        ctx.closePath()

        // xLabel
        const xTextWidth =
            ctx.measureText(data.labels?.[xValue] || '').width + 10
        ctx.beginPath()
        ctx.fillStyle = 'rgba(132, 132, 132, 0.9)'
        ctx.roundRect(
            event.nativeEvent.offsetX - xTextWidth / 2,
            bottom,
            xTextWidth,
            20,
            4
        )
        ctx.fill()
        ctx.closePath()
        ctx.beginPath()
        ctx.fillStyle = '#fff'
        ctx.textAlign = 'center'
        ctx.fillText(
            data.labels?.[xValue] || '',
            event.nativeEvent.offsetX,
            bottom + 10
        )
        ctx.closePath()
    }

    function crossLine(event: any) {
        const chart = chartRef.current
        if (!!!chart) return
        const {
            canvas,
            ctx,
            chartArea: { left, right, top, bottom },
        } = chart
        chart.update('none')
        ctx.restore()
        ctx.strokeStyle = '#000'
        ctx.setLineDash([1, 5])
        ctx.lineWidth = 1

        if (
            event.nativeEvent.offsetY >= top &&
            event.nativeEvent.offsetY <= bottom &&
            event.nativeEvent.offsetX >= left &&
            event.nativeEvent.offsetX <= right
        ) {
            // Horizontal Line
            ctx.beginPath()
            ctx.moveTo(left, event.nativeEvent.offsetY)
            ctx.lineTo(right, event.nativeEvent.offsetY)
            ctx.stroke()
            ctx.closePath()

            // Vertical Line
            ctx.beginPath()
            ctx.moveTo(event.nativeEvent.offsetX, top)
            ctx.lineTo(event.nativeEvent.offsetX, bottom)
            ctx.stroke()
            ctx.closePath()

            crossLineLabel(chart, event)
        }
    }

    function getGradient(
        ctx: any,
        chartArea: {
            left: number
            right: number
            top: number
            bottom: number
            height: number
            width: number
        },
        config: any,
        data: any,
        scales: { x: any; y: any }
    ) {
        const { left, right, top, bottom, height, width } = chartArea
        const { x, y } = scales
        const gradientBorder = ctx.createLinearGradient(0, 0, 0, bottom)
        const shift =
            y.getPixelForValue(
                config?._config?.options?.plugins?.dottedLine?.options[0].value
            ) / bottom
        gradientBorder.addColorStop(0, '#3E63DD')
        gradientBorder.addColorStop(shift, '#3E63DD')
        gradientBorder.addColorStop(shift, '#3E9B4F') // Grass10
        gradientBorder.addColorStop(1, '#3E9B4F')
        return gradientBorder
    }

    const dottedLine = {
        id: 'dotted-line',
        beforeDatasetDraw(chart: any, args: any, options: any) {
            const {
                ctx,
                data,
                chartArea: { left, right, width },
                scales,
                config,
            } = chart
            config?._config?.options?.plugins?.dottedLine?.options.forEach(
                (option: {
                    value: number
                    labelRight: number | string
                    labelLeft: number | string
                }) => {
                    ctx.save()

                    // DottedLine
                    ctx.beginPath()
                    ctx.strokeStyle = '#555'
                    ctx.setLineDash([1, 5])
                    ctx.lineWidth = 1
                    ctx.moveTo(left, scales.y.getPixelForValue(option.value))
                    ctx.lineTo(right, scales.y.getPixelForValue(option.value))
                    ctx.stroke()
                    ctx.closePath()

                    // Label Right
                    // if (width >= 640) {
                    //     const textWidth =
                    //         ctx.measureText(option.labelRight.toString())
                    //             .width + 10
                    //     ctx.beginPath()
                    //     ctx.fillStyle = 'rgba(132, 132, 132, 0.9)'
                    //     ctx.textAlign = 'center'
                    //     ctx.fillText(
                    //         option.labelRight.toString(),
                    //         right + textWidth / 2,
                    //         scales.y.getPixelForValue(option.value)
                    //     )
                    //     ctx.closePath()
                    // }

                    // Label Left
                    const textWidthLeft =
                        ctx.measureText(option.labelLeft.toString()).width + 10
                    ctx.beginPath()
                    ctx.fillStyle = 'rgba(132, 132, 132, 0.9)'
                    ctx.roundRect(
                        left - textWidthLeft,
                        scales.y.getPixelForValue(option.value) - 10,
                        textWidthLeft,
                        20,
                        4
                    )
                    ctx.fill()
                    ctx.fillStyle = '#fff'
                    ctx.textAlign = 'center'
                    ctx.fillText(
                        option.labelLeft.toString(),
                        left - textWidthLeft / 2,
                        scales.y.getPixelForValue(option.value)
                    )
                    ctx.closePath()
                }
            )
            // Reset line dash style
            ctx.setLineDash([])
        },
    }

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Filler,
        Title,
        Tooltip,
        Legend,
        dottedLine,
        zoomPlugin
    )

    const labels: string[] = priceHistoryData.map((item) =>
        new Date(item.timestamp).toLocaleDateString()
    )

    const options = {
        responsive: true,
        scales: {
            x: {
                min: labels[0],
                max: labels[labels.length - 1],
                grid: {
                    color: theme === 'light' ? '#E4E4E4' : '#222222',
                },
            },
            y: {
                grid: {
                    color: theme === 'light' ? '#E4E4E4' : '#222222',
                },
            },
        },
        plugins: {
            legend: {
                position: 'bottom' as const,
                labels: {
                    boxHeight: 1,
                },
            },
            title: {
                display: true,
                text: 'Price History Chart',
            },
            dottedLine: {
                options: [
                    {
                        value: targetPrice,
                        labelRight: 'Target Price',
                        labelLeft: targetPrice,
                    },
                    {
                        value: initialPrice,
                        labelRight: 'Initial Price',
                        labelLeft: initialPrice,
                    },
                ],
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'x' as const,
                },
                // zoom: {
                //     wheel: {
                //         enabled: true,
                //         speed: 0.05,
                //     },
                //     pinch: {
                //         enabled: true,
                //     },
                //     mode: 'x' as const,
                // },
                limits: {
                    x: {
                        minRange: 4,
                    },
                },
            },
        },
        // layout: {
        //     padding: {
        //         right: 100,
        //     },
        // },
        pointRadius: 3,
        pointBorderColor: pointBorderColor,
        pointBackgroundColor: pointBackgroundColor,
        pointBorderWidth: pointBorderWidth,
        tension: 0.05,
        stepped: true,
        maintainAspectRatio: false,
    }

    const data = {
        labels,
        datasets: [
            {
                label: 'Price',
                data: priceHistoryData.map((price) => price.price),
                // borderColor: (context: any) => {
                //     // const chart = context.chart
                //     // const { ctx, chartArea, data, scales, config } = chart
                //     // if (!!chartArea) {
                //     //     return getGradient(ctx, chartArea, config, data, scales)
                //     // }
                //     return '#3E63DD'
                // },
                borderColor: '#3E63DD',
            },
        ],
    }

    return (
        <Flex direction="column" className="w-full">
            <Line
                ref={chartRef}
                data={data}
                options={options}
                onMouseMove={crossLine}
                onMouseEnter={(event: any) =>
                    (event.currentTarget.style.cursor = 'grab')
                }
                onMouseDown={
                    // Set cursor to grabbing
                    (event: any) =>
                        (event.currentTarget.style.cursor = 'grabbing')
                }
                onMouseUp={
                    // Set cursor back to grab
                    (event: any) => (event.currentTarget.style.cursor = 'grab')
                }
                className="h-full max-h-[400px] w-full lg:w-[950px] chart-mobile"
            />
            <Box className="md:max-w-64">
                <Select.Root
                    defaultValue="3"
                    onValueChange={filterPriceHistoryByDate}
                >
                    <Select.Trigger />
                    <Select.Content position="popper">
                        <Select.Group>
                            <Select.Item value="3">Last 3 Months</Select.Item>
                            <Select.Item value="6">Last 6 Months</Select.Item>
                            <Select.Item value="12">Last Year</Select.Item>
                            <Select.Item value="24">Last 2 Years</Select.Item>
                        </Select.Group>
                    </Select.Content>
                </Select.Root>
            </Box>
        </Flex>
    )
}

export default PriceHistoryChart
