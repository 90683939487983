import { z } from 'zod'

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/ // At least one lowercase letter, one uppercase letter, one number, and at least 8 characters

export const signupSchema = z
    .object({
        email: z.string().min(1, 'Email is required').email(),
        password: z
            .string()
            .min(1, 'Password is required')
            .regex(
                passwordRegex,
                'Password must contain at least one lowercase letter, one uppercase letter, one number, and at least 8 characters'
            ),
        passwordConfirm: z
            .string()
            .min(1, 'Password confirmation is required')
            .regex(
                passwordRegex,
                'Password must contain at least one lowercase letter, one uppercase letter, one number, and at least 8 characters'
            ),
        firstName: z.string().min(1, 'First name is required'),
        lastName: z.string().min(1, 'Last name is required'),
        birthDate: z
            .string()
            .min(1, 'Birth date is required')
            .refine((data) => {
                const date = new Date(data)
                const fourYearsAgo = new Date()
                fourYearsAgo.setFullYear(fourYearsAgo.getFullYear() - 6)
                return date.getTime() <= fourYearsAgo.getTime()
            }, 'You must be at least 6 years old'),
        country: z.string().min(1, 'Country is required'),
    })
    .refine((data) => data.password === data.passwordConfirm, {
        message: 'Passwords do not match',
        path: ['passwordConfirm'],
    })
