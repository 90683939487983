import { Flex, Heading, Text } from '@radix-ui/themes'
import { FC } from 'react'
import { Pricing } from '../../shared/components/Pricing'
import ReactGA from 'react-ga4'

const Prices: FC = () => {
    ReactGA.send({
        hitType: 'pageview',
        page: '/prices',
        title: 'Prices',
    })
    return (
        <Flex
            direction="column"
            justify="start"
            className="w-full h-full mx-4 my-16 sm:mx-6 sm:my-6"
        >
            <Flex direction="column" gap="4" className="mb-24">
                <Heading size="5" weight="medium">
                    Prices
                </Heading>
                <Text weight="regular">
                    Welcome to the prices page. Compare PriceTracker pricing
                    plans and choose the one that fits your needs.
                </Text>
            </Flex>
            <Flex
                align="center"
                className=" h-full w-full"
                gap="9"
                direction="column"
                id="Pricing"
            >
                <Pricing />
            </Flex>
        </Flex>
    )
}

export default Prices
