import React, { FC, useState } from 'react'
import {
    Box,
    Container,
    Flex,
    Heading,
    Avatar,
    Text,
    Separator,
    Badge,
    AspectRatio,
    Link,
    Skeleton,
} from '@radix-ui/themes'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import { useQuery } from 'react-query'
import axios from 'axios'
import { ArticleProps, Section } from '../types'

const Article: FC = () => {
    const [article, setArticle] = useState<ArticleProps>()
    const params = useParams()
    const slug = params?.slug

    const id = slug?.split('-').pop()

    const { isLoading } = useQuery(['article', id], () =>
        axios
            .get(`/api/v1/blog/articles/${id}`)
            .then((res) => setArticle(res.data))
            .catch((err) => {
                console.error(err)
                return null
            })
    )

    const props: ArticleProps = {
        _id: '624f1b3b1b1b1b1b1b1b1b1b',
        title: 'Price Tracker – Track prices on any website – pricetracker.cc',
        description:
            'This is the story of how I built [pricetracker.cc](https://pricetracker.cc), a simple, intuitive and versatile price tracker to help you with online shopping.',
        author: 'Gabriel Hall',
        content: [
            {
                heading: '',
                body: '',
                media: {
                    type: 'image',
                    url: 'https://miro.medium.com/v2/resize:fit:1400/format:webp/1*u64EUfYE1Z3_KG3tblxIFg.png',
                    alt: 'pricetracker.cc landing page',
                },
            },
            {
                heading: 'The Problem',
                body: 'Back in **October 2023** I was looking for a pair of shoes - some casual leather sneakers and I found a nice pair, but they were _expensive_ so I decided to wait until they were on sale. I checked their price every day for like **2 weeks**, and then gradually stopped looking for a price drop. Then, in **December**, just by random choice I checked the price again, and it had dropped but they had no more stock. 😕',
            },
            {
                heading: 'The Idea',
                body: 'After a couple of weeks I was looking for a pair of jeans, and again, I found a nice expensive pair, and I also decided to wait for a price drop. But this time, after searching for a solution online and not finding any except for Amazon price trackers, I built a simple script that checked the price of the jeans every day on this website and when it changed sent me an email. Then when the price dropped I received an email and happily bought my pair.\n\nThis made me think, _what if I now want to do this but for another website? Or other multiple websites?_ Doing a script for each one would just be way too unbearable. This is where the idea comes in, **what if I built an app that works with almost any website and adapts to all the different presented structures and elements**, and the best part, I only have to do it once?',
            },
            {
                heading: 'The Solution',
                body: 'After testing and validating this idea, I started to build it. **Six months have passed**, and I am now launching an MVP.\n\nThe way [pricetracker.cc](https://pricetracker.cc) works is fairly simple. I need the URL of the item you want to track and its current price (_this helps the algorithm find it the first time_). Once you provide this information, the rest is handled on my side. If the tracker is successfully created, it will check the price every day and send you an email when there is a price change. You can even define a target price and choose to receive notifications only when it reaches that amount. When you no longer need the tracker, you can delete it or remove all notifications.\n\nNow, you can have peace of mind, relax, and let me handle price tracking for you.',
            },
        ],
        tags: ['Starter Story'],
        createdAt: '2024-03-22T08:06:19.153+00:00',
    }
    if (!article) return null
    const { title, description, content, author, createdAt, tags } = article
    return (
        <Container size="2" className="mt-6">
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
            </Helmet>
            <Flex
                direction="column"
                gap="5"
                justify="start"
                align="start"
                className="h-full w-full p-4"
            >
                <Flex gap="2" className="mb-6">
                    <Skeleton loading={isLoading}>
                        <Link href="/blog">blog</Link>
                    </Skeleton>{' '}
                    <span>/</span>
                    <Skeleton loading={isLoading}>
                        <Link href={`/blog/${slug}`}>
                            {slug?.split('-').slice(0, -1).join(' ')}
                        </Link>
                    </Skeleton>
                </Flex>
                <Box>
                    <Skeleton loading={isLoading}>
                        <Heading size="7">{title}</Heading>
                    </Skeleton>
                </Box>
                <Flex gap="5">
                    <Skeleton loading={isLoading}>
                        <Avatar
                            fallback={author.charAt(0)}
                            size="4"
                            radius="full"
                        />
                    </Skeleton>
                    <Skeleton loading={isLoading}>
                        <Flex direction="column">
                            <Text>{author}</Text>
                            <Text>{new Date(createdAt).toDateString()}</Text>
                        </Flex>
                    </Skeleton>
                </Flex>
                <Separator orientation="horizontal" className="w-full" />
                {/* Content  */}
                <Flex direction="column" gap="6">
                    <Flex direction="column" gap="3">
                        {/* description */}
                        <Skeleton loading={isLoading}>
                            <ReactMarkdown>{description}</ReactMarkdown>
                        </Skeleton>
                    </Flex>
                    {/* section */}
                    {content?.map((section: Section, index: number) => (
                        <Flex direction="column" gap="3" key={index}>
                            {!!section?.heading && (
                                <Skeleton loading={isLoading}>
                                    <Box>
                                        <Heading size="4">
                                            {section?.heading}
                                        </Heading>
                                    </Box>
                                </Skeleton>
                            )}
                            {!!section?.body &&
                                section.body.split('\n').map((text, index) => (
                                    <Skeleton loading={isLoading}>
                                        <ReactMarkdown
                                            key={index}
                                            className=" text-justify"
                                        >
                                            {text}
                                        </ReactMarkdown>
                                    </Skeleton>
                                ))}
                            {!!section?.media && (
                                <Skeleton loading={isLoading}>
                                    <AspectRatio ratio={16 / 9}>
                                        <img
                                            src={section?.media?.url}
                                            alt={section?.media?.alt}
                                            className="object-cover max-w-xl max-h-max rounded-lg"
                                        />
                                    </AspectRatio>
                                </Skeleton>
                            )}
                        </Flex>
                    ))}
                </Flex>
                <Separator orientation="horizontal" className="w-full" />
                {/* Tags  */}
                <Flex gap="2">
                    {tags.map((tag, index) => (
                        <Skeleton loading={isLoading}>
                            <Badge key={index}>{tag}</Badge>
                        </Skeleton>
                    ))}
                </Flex>
                <Separator orientation="horizontal" className="w-full" />
                {/* Footer */}
                {/* <Flex direction="column">
                    <Box>
                        <Heading size="4">Comments Heading</Heading>
                    </Box>

                </Flex> */}
            </Flex>
        </Container>
    )
}

export default Article
