import { EnvelopeClosedIcon, InfoCircledIcon } from '@radix-ui/react-icons'
import * as Label from '@radix-ui/react-label'
import { Box, Checkbox, Flex, Text, Tooltip } from '@radix-ui/themes'
import { FC } from 'react'
import CheckboxCard from '../../shared/components/CheckboxCard/CheckboxCard'
import React from 'react'
import { NotificationChannel } from '../../shared/types'

interface NotificationChannelsProps {
    id?: string
    activeChannels: NotificationChannel[]
    onCheckedChange?: (checked: boolean, value: NotificationChannel) => void
}

const NotificationChannels: FC<NotificationChannelsProps> = (
    props: NotificationChannelsProps
) => {
    const { onCheckedChange, activeChannels } = props
    const notificationChannels: NotificationChannel[] = ['email']
    return (
        <Box maxWidth="600px">
            <Flex direction="column" align="start" gap="1">
                <Label.Root htmlFor={'Notification Channels'}>
                    <Flex align="center" gap="2">
                        Notification Channels
                        <Tooltip content={'Choose your notification channels'}>
                            <InfoCircledIcon />
                        </Tooltip>
                    </Flex>
                </Label.Root>

                {notificationChannels.map((channel) => (
                    <Box className="w-full" key={channel}>
                        <Flex
                            justify="start"
                            align="center"
                            wrap="wrap"
                            gap="6"
                        >
                            <CheckboxCard
                                icon={<EnvelopeClosedIcon />}
                                name="Email"
                                value={channel}
                                defaultChecked={activeChannels.includes(
                                    channel
                                )}
                                onCheckedChange={(checked, value) =>
                                    onCheckedChange &&
                                    onCheckedChange(
                                        checked,
                                        value as NotificationChannel
                                    )
                                }
                            />
                        </Flex>
                    </Box>
                ))}
            </Flex>
        </Box>
    )
}

export default NotificationChannels
