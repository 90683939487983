import { Flex, Box, Heading, Container, Text } from '@radix-ui/themes'
import React, { FC } from 'react'
import ReactGA from 'react-ga4'

const PrivacyPolicy: FC = () => {
    ReactGA.send({
        hitType: 'pageview',
        page: '/privacy-policy',
        title: 'Privacy Policy',
    })
    return (
        <Container size="2" className="mt-6">
            <Flex
                direction="column"
                gap="5"
                justify="start"
                align="start"
                className="h-full w-full p-4"
            >
                <Box>
                    <Heading size="4">Privacy Policy</Heading>
                </Box>
                <Flex direction="column" gap="1">
                    <Text>
                        Your privacy is important to us. It is Pricetracker's
                        policy to respect your privacy regarding any information
                        we may collect from you across our website.
                    </Text>
                </Flex>
                <Flex direction="column" gap="1">
                    <Text>
                        We only ask for personal information when we truly need
                        it to provide a service to you. We collect it by fair
                        and lawful means, with your knowledge and consent. We
                        also let you know why we're collecting it and how it
                        will be used. All information collected is used or to
                        provide the service it self, or for internal analytics.
                    </Text>
                </Flex>
                <Flex direction="column" gap="1">
                    <Text>
                        Information we collect: Email address, first name, last
                        name, country, and birth date.
                    </Text>
                </Flex>
                <Flex direction="column" gap="1">
                    <Text>
                        We only retain collected information for as long as
                        necessary to provide you with your requested service.
                        What data we store, we'll protect within commercially
                        acceptable means to prevent loss and theft, as well as
                        unauthorised access, disclosure, copying, use or
                        modification.
                    </Text>
                </Flex>
                <Flex direction="column" gap="1">
                    <Text>
                        We don't share any personally identifying information
                        publicly or with third-parties, except when required to
                        by law.
                    </Text>
                </Flex>
                <Flex direction="column" gap="1">
                    <Text>
                        We act in the capacity of a data controller and a data
                        processor with regard to the personal data processed
                        through Pricetracker and the services in terms of the
                        applicable data protection laws, including the EU
                        General Data Protection Regulation (GDPR).
                    </Text>
                </Flex>
                <Flex direction="column" gap="1">
                    <Text>
                        Our website may link to external sites that are not
                        operated by us. Please be aware that we have no control
                        over the content and practices of these sites, and
                        cannot accept responsibility or liability for their
                        respective privacy policies.
                    </Text>
                </Flex>
                <Flex direction="column" gap="1">
                    <Text>
                        You are free to refuse our request for your personal
                        information, with the understanding that we may be
                        unable to provide you with some of your desired
                        services.
                    </Text>
                </Flex>
                <Flex direction="column" gap="1">
                    <Text>
                        Your continued use of our website will be regarded as
                        acceptance of our practices around privacy and personal
                        information. If you have any questions about how we
                        handle user data and personal information, feel free to
                        contact us.
                    </Text>
                </Flex>
            </Flex>
        </Container>
    )
}

export default PrivacyPolicy
