import { CheckIcon, Cross1Icon } from '@radix-ui/react-icons'
import {
    Card,
    Text,
    Flex,
    Button,
    Box,
    Link,
    RadioGroup,
} from '@radix-ui/themes'
import PricingCardTag from './PricingCardTag'
import { FC, useState } from 'react'
import React from 'react'
import { useMutation } from 'react-query'
import { useLoginDialog, useToast } from '../../../shared/hooks'
import { userAtom } from '../../../shared/atoms'
import { useRecoilValue } from 'recoil'

export type Billing = {
    month: BillingConfig
    year: BillingConfig
}

export type BillingConfig = {
    price: number
    discount: number
    paymentLink?: string
    priceId?: string
}

interface PricingCardProps {
    id: string
    name: string
    billing: Billing
    tag?: string
    bulletPoints: {
        text: React.ReactNode
        disabled: boolean
    }[]
    note?: string
}

const PricingCard: FC<PricingCardProps> = (props: PricingCardProps) => {
    const user = useRecoilValue(userAtom)
    const { openLoginDialog } = useLoginDialog()
    const [billingPeriod, setBillingPeriond] = useState<'year' | 'month'>(
        'year'
    )
    const { setToast } = useToast()
    const getPaymentLink = useMutation(
        'paymentLink',
        async () => {
            const res = await fetch(
                `/api/v1/pricing/payment-link?planId=${props.id}&billingPeriod=${billingPeriod}`
            )
            const data = await res.json()
            if (!res.ok) {
                console.log(data)
                if (data?.body?.message) throw new Error(data.body.message)
                throw new Error('Network response was not ok')
            }
            return data
        },
        {
            onError: (error: any) => {
                console.error('Error getting payment link', error)
                setToast({
                    title: 'Error getting payment link',
                    type: 'error',
                })
            },
            onSuccess: (data) => {
                console.log('Payment link', data)
                const paymentLink =
                    data.paymentLink + '?prefilled_email=' + data.email
                // Update href of hidden anchor element
                const paymentLinkAnchor = document.getElementById(
                    'paymentLink'
                ) as HTMLAnchorElement
                if (!paymentLinkAnchor) return
                paymentLinkAnchor.href = paymentLink
                // Simulate click on anchor to open link
                paymentLinkAnchor.click()
            },
        }
    )

    const getDiscountedPrice: (price: number, discount: number) => string = (
        price,
        discount
    ) => {
        return discount > 0
            ? ((price * (100 - discount)) / 100).toFixed(2)
            : price.toFixed(2)
    }

    return (
        <Box className="relative w-full sm:w-96 mx-4">
            {!!props?.tag && <PricingCardTag text={props.tag} />}
            <Card className="p-4   overflow-visible">
                <Flex direction="column" gap="5">
                    <Box>
                        <Text size="6">
                            <strong>{props.name}</strong>
                        </Text>
                        <Flex gap="3" align="end" justify="start">
                            {props.billing?.[billingPeriod].discount > 0 && (
                                <Text as="div" align="left">
                                    <s>
                                        $
                                        {props.billing?.[
                                            billingPeriod
                                        ].price.toFixed(2)}
                                    </s>
                                </Text>
                            )}
                            <Flex align="end" justify="start" gap="1">
                                <Text size="6" color="blue">
                                    <strong>
                                        $
                                        {getDiscountedPrice(
                                            props.billing?.[billingPeriod]
                                                .price,
                                            props.billing?.[billingPeriod]
                                                .discount
                                        )}
                                    </strong>
                                </Text>
                                <Text size="1" color="blue" className="pb-1">
                                    /{billingPeriod}
                                </Text>
                            </Flex>
                        </Flex>
                    </Box>
                    <Box>
                        <ul>
                            {props.bulletPoints.map((bulletPoint, index) => {
                                return (
                                    <li key={index}>
                                        <Flex
                                            align="center"
                                            gap="2"
                                            className={
                                                bulletPoint.disabled
                                                    ? 'text-[var(--gray-a10)]'
                                                    : ''
                                            }
                                        >
                                            {bulletPoint.disabled ? (
                                                <Cross1Icon />
                                            ) : (
                                                <CheckIcon />
                                            )}
                                            {bulletPoint.text}
                                        </Flex>
                                    </li>
                                )
                            })}
                        </ul>
                    </Box>
                    <Box className="mb-2">
                        <Text>Billing</Text>
                        <RadioGroup.Root
                            defaultValue="year"
                            name="biling"
                            value={billingPeriod}
                            onValueChange={() =>
                                setBillingPeriond(
                                    billingPeriod === 'year' ? 'month' : 'year'
                                )
                            }
                        >
                            <RadioGroup.Item value="year">
                                Yearly - $
                                {getDiscountedPrice(
                                    props.billing.year.price,
                                    props.billing.year.discount
                                )}{' '}
                                {props.billing.year.discount === 100
                                    ? `- after trial $${props.billing.year.price.toFixed(
                                          2
                                      )}`
                                    : ''}
                            </RadioGroup.Item>
                            <RadioGroup.Item value="month">
                                Monthly - $
                                {getDiscountedPrice(
                                    props.billing.month.price,
                                    props.billing.month.discount
                                )}{' '}
                                {props.billing.month.discount === 100
                                    ? 'After Trial'
                                    : ''}
                            </RadioGroup.Item>
                        </RadioGroup.Root>
                    </Box>
                    <Box>
                        <Button
                            variant="soft"
                            className="w-full"
                            size="3"
                            onClick={() => {
                                if (!!user && !!user.isAuthenticated) {
                                    getPaymentLink.mutate()
                                } else {
                                    setToast({
                                        title: 'Please login to continue',
                                        type: 'error',
                                    })
                                    openLoginDialog()
                                }
                            }}
                        >
                            Choose plan
                        </Button>
                        <a
                            href="#"
                            target="_blank"
                            id="paymentLink"
                            style={{ display: 'none' }}
                        />{' '}
                        {/* Hidden anchor */}
                        <Text size="1" as="div" color="gray" className="h-2">
                            {props.note}
                        </Text>
                    </Box>
                </Flex>
            </Card>
        </Box>
    )
}

export default PricingCard
