import { FC, useEffect, useRef } from 'react'
import { Text, Heading, Flex, Button } from '@radix-ui/themes'
import { useNavigate } from 'react-router-dom'
import CreateTrackerSimpleForm from './CreateTrackerSimpleForm/CreateTrackerSimpleForm'
import { ArrowDownIcon, CheckIcon, Cross2Icon } from '@radix-ui/react-icons'
import { trackersAtom } from '../../shared/atoms'
import { useSetRecoilState } from 'recoil'
import ReactGA from 'react-ga4'
import { UserTrackers } from '../../shared/types'
import Cookies from 'js-cookie'
import { Pricing } from '../../shared/components/Pricing'
import { useLocation } from 'react-router-dom'
import React from 'react'
import Faq from '../../shared/components/FAQs/Faq'
import { Reviews } from './Reviews'

const Home: FC = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const setTrackers = useSetRecoilState(trackersAtom)
    const formRef = useRef<HTMLDivElement>(null)

    ReactGA.send({
        hitType: 'pageview',
        page: '/',
        title: 'Home',
    })

    useEffect(() => {
        if (window.location.hash === '#Pricing') {
            const element = document.getElementById('Pricing')
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' })
            }
        }
    }, [location])
    return (
        <Flex direction="column" className="w-full h-full" justify="start">
            <Flex
                justify="center"
                align="center"
                className="h-dvh w-full p-4"
                ref={formRef}
            >
                <Flex
                    direction="column"
                    gap="2"
                    justify="center"
                    align="center"
                >
                    {/* Title */}
                    <Flex
                        direction="column"
                        gap="4"
                        justify="center"
                        align="center"
                    >
                        <Heading size="9" weight="medium" align="center">
                            Price tracking made easy
                        </Heading>
                        <Text>
                            {/* PriceTracker saves <strong>$50</strong> on
                            average per user per month */}
                            Stop wasting time checking prices. I'll do it for
                            you.
                        </Text>
                    </Flex>
                    {/* Form */}
                    <CreateTrackerSimpleForm
                        onSuccess={(newTracker) => {
                            // Add trackers to atom
                            setTrackers((prev) => {
                                return [...prev, newTracker]
                            })
                            // Add the new tracker to cookies
                            // const savedTrackers = Cookies.get('trackers')
                            // if (
                            //     savedTrackers &&
                            //     Array.isArray(JSON.parse(savedTrackers))
                            // ) {
                            //     const trackers: UserTrackers[] =
                            //         JSON.parse(savedTrackers)
                            //     trackers.push(newTracker)
                            //     Cookies.remove('trackers')
                            //     Cookies.set(
                            //         'trackers',
                            //         JSON.stringify(trackers),
                            //         {
                            //             expires: 1,
                            //         }
                            //     )
                            // } else {
                            //     Cookies.set(
                            //         'trackers',
                            //         JSON.stringify([newTracker]),
                            //         {
                            //             expires: 1,
                            //         }
                            //     )
                            // }
                            // const check = Cookies.get('trackers')
                            // console.log(check)
                            // Navigate to the trackers page
                            navigate('/trackers')
                        }}
                    />
                </Flex>
            </Flex>
            {/* The problem and The Solution */}
            <Flex
                justify="center"
                align="center"
                className="w-full p-4 h-auto"
                gap="9"
                direction="column"
            >
                <Heading size="9" weight="medium" className="text-center">
                    Tired of wasting time and money?
                </Heading>
                <Flex
                    gap="8"
                    justify="center"
                    align="center"
                    className="my-12 flex-wrap"
                >
                    {/* <Flex
                        direction="column"
                        gap="2"
                        justify="center"
                        align="center"
                        className="bg-[var(--red-4)] p-4 rounded-lg w-full sm:w-[445px] h-auto sm:h-64 mb-8 sm:mb-0"
                    >
                        <Flex
                            direction="column"
                            gap="4"
                            justify="start"
                            align="start"
                            className="w-full h-full p-4"
                        >
                            <Text>
                                <strong className="text-[var(--red-10)] flex justify-start items-center">
                                    Tracking Prices without PriceTracker
                                </strong>{' '}
                            </Text>
                            <Flex align="center" gap="1">
                                <span>
                                    <Cross2Icon />
                                </span>
                                <Text>
                                    Miss limited-time deals & pay full price!
                                </Text>
                            </Flex>
                            <Flex align="center" gap="1">
                                <span>
                                    <Cross2Icon />
                                </span>
                                <Text>
                                    Waste time refreshing & comparing prices!
                                </Text>
                            </Flex>
                            <Flex align="center" gap="1">
                                <span>
                                    <Cross2Icon />
                                </span>
                                <Text>
                                    Buyer's remorse? Ugh, price drops after you
                                    buy!
                                </Text>
                            </Flex>
                        </Flex>
                    </Flex> */}
                    <Flex
                        direction="column"
                        gap="4"
                        justify="center"
                        align="center"
                        className="bg-gradient-to-br from-[var(--indigo-2)] to-[var(--blue-5)] p-4 rounded-lg w-full sm:w-[800px] h-auto sm:h-64"
                    >
                        <Flex
                            direction="column"
                            gap="4"
                            justify="start"
                            align="start"
                            className="w-full h-full p-4 text-[var(--indigo-11)]"
                        >
                            <Text size="5" className="mb-4">
                                <strong className="text-[var(--indigo-11)]">
                                    Tracking Prices + PriceTracker
                                </strong>{' '}
                            </Text>
                            <Flex align="center" gap="2">
                                <span className="bg-[var(--gray-1)] rounded-full h-5 w-5 flex justify-center items-center">
                                    <CheckIcon className="h-5" />
                                </span>
                                <Text weight="medium">
                                    Track favorite stores, never miss deals
                                    again!
                                </Text>
                            </Flex>
                            <Flex align="center" gap="2">
                                <span className="bg-[var(--gray-1)] rounded-full h-5 w-5 flex justify-center items-center">
                                    <CheckIcon className="h-5" />
                                </span>
                                <Text weight="medium">
                                    Automated tracking, relax & stop refreshing!
                                </Text>
                            </Flex>
                            <Flex align="center" gap="2">
                                <span className="bg-[var(--gray-1)] rounded-full h-5 w-5 flex justify-center items-center">
                                    <CheckIcon className="h-5" />
                                </span>
                                <Text weight="medium">
                                    Get instant alerts for price drops you care
                                    about!
                                </Text>
                            </Flex>
                            <Flex align="center" gap="2">
                                <span className="bg-[var(--gray-1)] rounded-full h-5 w-5 flex justify-center items-center">
                                    <CheckIcon className="h-5" />
                                </span>
                                <Text weight="medium">
                                    Save money on everything, never pay full
                                    price!
                                </Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
                {/* Call to Action */}
                <Flex direction="column" gap="6">
                    <Button
                        variant="soft"
                        size="3"
                        onClick={() => {
                            // Focus on the form
                            formRef.current?.scrollIntoView({
                                behavior: 'smooth',
                            })
                        }}
                    >
                        Create a Tracker Now
                    </Button>
                    <Flex direction="column" justify="center" align="center">
                        <Text>Tell me more</Text>
                        <ArrowDownIcon />
                    </Flex>
                </Flex>
            </Flex>
            {/* Reviews */}
            <Flex
                justify="center"
                align="center"
                className=" h-auto w-full my-12"
                gap="9"
                direction="column"
                id="Reviews"
            >
                <Reviews />
            </Flex>

            {/* Pricing */}
            <Flex
                justify="center"
                align="center"
                className=" h-auto w-full my-12"
                gap="9"
                direction="column"
                id="Pricing"
            >
                <Pricing />
            </Flex>
            {/* FAQs */}
            <Flex
                justify="center"
                align="center"
                className="h-auto w-full"
                gap="9"
                direction="column"
            >
                <Faq />
            </Flex>
        </Flex>
    )
}

export default Home
