import { FC } from 'react'
import { Flex, Tooltip, Box, Button, Heading, Text } from '@radix-ui/themes'
import * as Label from '@radix-ui/react-label'
import { InfoCircledIcon } from '@radix-ui/react-icons'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from 'react-query'
import { useSetRecoilState } from 'recoil'
import Cookies from 'js-cookie'
import { FaGoogle } from 'react-icons/fa'

import FormErrorMessage from '../FormErrorMessage'
import FormInput from '../Input/FormInput'
import { userAtom } from '../../../atoms'
import { loginSchema } from '../../../../utils'
import axios from 'axios'

interface LoginFormProps {
    onSuccess?: () => void
}

type LoginFormInputs = {
    email: string
    password: string
}

const LoginForm: FC<LoginFormProps> = ({ onSuccess }) => {
    const setUser = useSetRecoilState(userAtom)
    const formMethods = useForm<LoginFormInputs>({
        resolver: zodResolver(loginSchema),
        reValidateMode: 'onChange',
        defaultValues: {
            email: '',
            password: '',
        },
    })

    const {
        handleSubmit,
        setError,
        formState: { errors },
        clearErrors,
    } = formMethods
    const loginMutation = useMutation(
        ({ email, password }: LoginFormInputs) =>
            axios
                .post('/api/v1/users/login', { email, password })
                .then((res) => res.data)
                .catch((error) => {
                    if (axios.isAxiosError(error) && error.response) {
                        console.log('error:', error)
                        const errorMessage =
                            error.response.data?.body?.message ||
                            error.response.data?.message ||
                            error.response?.statusText ||
                            'Network response was not ok'
                        throw new Error(errorMessage)
                    }
                    throw error
                }),
        {
            onError: (error: any) => {
                setError('root', {
                    type: 'manual',
                    message: error?.message,
                })
            },
            onSuccess: (data) => {
                setUser({
                    isAuthenticated: true,
                    ...data.user,
                })
                // Set cookies to persist data
                Cookies.set(
                    'user',
                    JSON.stringify({
                        email: data?.user?.email,
                        firstName: data?.user?.firstName,
                        lastName: data?.user?.lastName,
                    }),
                    { expires: 7 }
                )

                if (onSuccess) {
                    onSuccess()
                }
            },
        }
    )

    const sendVerificationEmail = async () => {
        try {
            await fetch('/api/v1/users/send-email-verify', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: formMethods.getValues('email'),
                }),
            })
        } catch (error) {
            console.log('error:', error)
        }
    }

    const onSubmit: SubmitHandler<LoginFormInputs> = (data) => {
        loginMutation.mutate(data)
    }

    const onError = (errors: any) => {
        console.log('errors:', errors)
    }

    return (
        <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit, onError)} className="w-full">
                <Flex
                    direction="column"
                    gap="3"
                    justify="center"
                    align="center"
                    width="100%"
                >
                    <Heading size="5" weight="medium">
                        Login to PriceTracker
                    </Heading>
                    <Flex
                        direction="column"
                        justify="start"
                        align="start"
                        className="w-full"
                    >
                        <Label.Root htmlFor="Email">
                            <Flex align="center" gap="2">
                                Email{' '}
                                <Tooltip content="Write your email here">
                                    <InfoCircledIcon />
                                </Tooltip>
                            </Flex>
                        </Label.Root>
                        <FormInput
                            name="email"
                            size="3"
                            placeholder="example@email.com"
                            variant="surface"
                            className="w-full"
                            type="email"
                        />
                        <FormErrorMessage field="email" />
                    </Flex>
                    <Flex
                        direction="column"
                        justify="start"
                        align="start"
                        className="w-full"
                    >
                        <Label.Root htmlFor="Password">
                            <Flex align="center" gap="2">
                                Password{' '}
                                <Tooltip content="Write your password here">
                                    <InfoCircledIcon />
                                </Tooltip>
                            </Flex>
                        </Label.Root>
                        <FormInput
                            name="password"
                            size="3"
                            placeholder="Password"
                            variant="surface"
                            className="w-full"
                            type="password"
                            // TODO show pw icon
                        />
                        <FormErrorMessage field="password" />
                    </Flex>

                    <Box className="w-2/3 m-4">
                        <Button
                            variant="soft"
                            loading={loginMutation.isLoading}
                            size="3"
                            color={
                                errors.root
                                    ? 'red'
                                    : loginMutation.isSuccess
                                    ? 'green'
                                    : 'indigo'
                            }
                            className={`w-full`}
                        >
                            Login
                        </Button>
                        <FormErrorMessage field="root" />
                        {/*Resend email error*/}
                        {errors.root?.message === 'Email not verified' ? (
                            <Button
                                size="1"
                                color="indigo"
                                className="cursor-pointer"
                                onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    sendVerificationEmail()
                                    clearErrors('root')
                                }}
                            >
                                Resend verification email
                            </Button>
                        ) : null}
                        <Button
                            variant="outline"
                            loading={loginMutation.isLoading}
                            size="3"
                            className={`w-full mt-4`}
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                window.location.href =
                                    process.env.NODE_ENV === 'production'
                                        ? 'https://pricetracker.cc/api/v1/auth/login/google'
                                        : 'http://localhost:5050/api/v1/auth/login/google'
                            }}
                        >
                            <FaGoogle /> Google
                        </Button>
                    </Box>
                </Flex>
            </form>
        </FormProvider>
    )
}

export default LoginForm
