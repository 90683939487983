import { Flex, Skeleton } from '@radix-ui/themes'
import { FC, useState } from 'react'
import PricingCard from './PricingCard'
import React from 'react'
import { useQuery } from 'react-query'
import { useToast } from '../../../shared/hooks'
import { Plan } from '../../../shared/types'

const Pricing: FC = () => {
    const { setToast } = useToast()
    const [plans, setPlans] = useState<Plan[]>([])
    const { isLoading } = useQuery(
        'plans',
        async () => {
            const res = await fetch('/api/v1/pricing/plans')
            if (!res.ok) throw new Error('Network response was not ok')
            return res.json()
        },
        {
            onError: (error: any) => {
                setToast({
                    title: 'Error getting plans',
                    type: 'error',
                })
            },
            onSuccess: (data) => {
                setPlans(data)
            },
        }
    )
    return (
        <Flex justify="center" align="center" gap="8" className="flex-wrap">
            {plans.map((plan) => {
                return (
                    <Skeleton loading={isLoading} key={plan._id}>
                        <PricingCard
                            key={plan._id}
                            id={plan._id}
                            name={plan.name}
                            billing={plan.billing}
                            tag={plan.tag}
                            bulletPoints={plan.bulletPoints}
                            note={plan.note}
                        />
                    </Skeleton>
                )
            })}
        </Flex>
    )
}

export default Pricing
