import { InfoCircledIcon } from '@radix-ui/react-icons'
import { Button, Flex, SegmentedControl, Tooltip } from '@radix-ui/themes'
import * as Label from '@radix-ui/react-label'
import { FC } from 'react'
import React from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import FormInput from '../../../shared/components/Form/Input/FormInput'
import FormErrorMessage from '../../../shared/components/Form/FormErrorMessage'
import { useMutation } from 'react-query'
import { zodResolver } from '@hookform/resolvers/zod'
import { deleteTrackerSchema } from '../../../utils'
import { useDeleteTrackerDialog } from '../../../shared/hooks'
import { useToast } from '../../../shared/hooks'
import Cookies from 'js-cookie'
import { trackersAtom } from '../../../shared/atoms'
import { useSetRecoilState } from 'recoil'
import axios from 'axios'

interface DeleteTrackerFormProps {
    id: string
    currentPrice?: number
    onSuccess?: () => void
    onCancel?: () => void
}

type DeleteTrackerFormInputs = {
    purchasePrice: number
}

const DeleteTrackerForm: FC<DeleteTrackerFormProps> = (
    props: DeleteTrackerFormProps
) => {
    const { id, currentPrice, onSuccess } = props
    const setTrackers = useSetRecoilState(trackersAtom)
    const { closeDeleteTrackerDialog } = useDeleteTrackerDialog()
    const { setToast } = useToast()
    const [productBought, setProductBought] = React.useState<
        'yes' | 'no' | undefined
    >()

    const formMethods = useForm<DeleteTrackerFormInputs>({
        resolver: zodResolver(deleteTrackerSchema),
        reValidateMode: 'onChange',
        defaultValues: {
            purchasePrice: currentPrice ?? 0,
        },
    })

    const { handleSubmit, setError, formState, watch } = formMethods

    const purchasePrice = watch('purchasePrice')

    const deleteTrackerMutation = useMutation(
        () =>
            fetch('/api/v1/users/trackers', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    credentials: 'include',
                },

                body: JSON.stringify({ id }),
            }).then(async (res) => {
                if (!res.ok) {
                    throw new Error('Failed to delete tracker')
                }
                return res.json()
            }),
        {
            onError: (error: any) => {
                setToast({
                    type: 'error',
                    title: 'Failed to delete tracker',
                    message: error.message,
                    duration: 4000,
                })
                setError('root', {
                    type: 'manual',
                    message: error?.message,
                })
            },
            onSuccess: () => {
                setToast({
                    type: 'success',
                    title: 'Tracker deleted',
                    message: 'Tracker was successfully deleted',
                    duration: 4000,
                })
                // Remove Tracker from cookies
                const savedTrackers = Cookies.get('trackers')
                if (savedTrackers && Array.isArray(JSON.parse(savedTrackers))) {
                    const trackers = JSON.parse(savedTrackers)
                    const updatedTrackers = trackers.filter(
                        (tracker: any) => tracker.trackerId !== id
                    )
                    Cookies.set('trackers', JSON.stringify(updatedTrackers), {
                        expires: 1,
                    })
                }
                // Remove Tracker from trackersAtom
                setTrackers((trackers) =>
                    trackers.filter((tracker) => tracker.trackerId !== id)
                )
                if (onSuccess) {
                    onSuccess()
                }
            },
        }
    )

    const trackerPurchaseMutation = useMutation(
        ({ purchasePrice }: DeleteTrackerFormInputs) =>
            axios
                .post('/api/v1/tracker-purchase/new', {
                    trackerId: id,
                    purchasePrice,
                })
                .then((res) => res.data)
                .catch((error) => {
                    if (axios.isAxiosError(error) && error.response) {
                        const errorMessage =
                            error.response.data?.body?.message ||
                            error.response.data?.message ||
                            error.response?.statusText ||
                            'Network response was not ok'
                        throw new Error(errorMessage)
                    }
                    throw error
                })
    )

    const onSubmit: SubmitHandler<DeleteTrackerFormInputs> = (data) => {
        deleteTrackerMutation.mutate()
        productBought === 'yes' && trackerPurchaseMutation.mutate(data)
    }

    const onError = (errors: any) => {
        console.log('errors:', errors)
    }

    const onCancel = () => {
        closeDeleteTrackerDialog()
    }

    return (
        <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit, onError)} className="w-full">
                <Flex
                    direction="column"
                    gap="3"
                    justify="center"
                    align="start"
                    width="100%"
                >
                    <Flex
                        direction="column"
                        justify="start"
                        align="start"
                        className="w-full"
                    >
                        <Label.Root htmlFor="Purchase Price">
                            <Flex align="center" gap="2">
                                Did you manage to buy the product?
                            </Flex>
                        </Label.Root>
                        <SegmentedControl.Root
                            defaultValue="inbox"
                            onValueChange={(value) =>
                                setProductBought(value as 'yes' | 'no')
                            }
                        >
                            <SegmentedControl.Item value="yes">
                                Yes
                            </SegmentedControl.Item>
                            <SegmentedControl.Item value="no">
                                No
                            </SegmentedControl.Item>
                        </SegmentedControl.Root>
                    </Flex>

                    {productBought === 'yes' && (
                        <Flex
                            direction="column"
                            justify="start"
                            align="start"
                            className={`w-full ${
                                productBought
                                    ? 'animate-slide-in-top'
                                    : 'animate-slide-in-bottom'
                            }`}
                        >
                            <Label.Root htmlFor="Purchase Price">
                                <Flex align="center" gap="2">
                                    At what price did you buy it?{' '}
                                    <Tooltip content="Write the price at which you bought the product. We need this information to be abel to sum how much you can save with promotions">
                                        <InfoCircledIcon />
                                    </Tooltip>
                                </Flex>
                            </Label.Root>
                            <FormInput
                                name="purchasePrice"
                                size="3"
                                placeholder={String(currentPrice ?? 10.0)}
                                variant="surface"
                                className="w-full"
                                type="text"
                                defaultValue={currentPrice ?? 10.0}
                            />
                            <FormErrorMessage field="purchasePrice" />
                        </Flex>
                    )}
                    <Flex justify="start" className="gap-3 mt-4 w-full">
                        <Button
                            variant="soft"
                            color="gray"
                            type="reset"
                            onClick={onCancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="solid"
                            color="red"
                            disabled={
                                !!!productBought ||
                                (productBought === 'yes' && !purchasePrice)
                            }
                            loading={deleteTrackerMutation.isLoading}
                        >
                            Delete Tracker
                        </Button>
                    </Flex>
                    <FormErrorMessage field="root" />
                </Flex>
            </form>
        </FormProvider>
    )
}

export default DeleteTrackerForm
